import React from "react"
import { graphql } from "gatsby"
import { Link } from "@material-ui/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Typography } from "@material-ui/core"

const ScriptIndex = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="MediaMonkey scripts" />
      <h1
        style={{
          // TODO: set this
          // marginTop: rhythm(1),
          marginBottom: 0,
        }}
      >
        MediaMonkey Scripts
      </h1>
      <Typography>
        I've sinced stopped development of these, but they're archived on
        GitHub. Feel free to fork them if desired! MMIP files are on the
        Releases page.
        <ul>
          <li>
            <Link href="https://github.com/Psykar/MM-LastFmImportPython/releases">
              LastFmImportPython
            </Link>
          </li>
          <li>
            <Link href="https://github.com/Psykar/LastFmLovedPlaylist/releases">
              LastFmLovedPlaylist
            </Link>
          </li>
          <li>
            <Link href="https://github.com/Psykar/LastFmImport/releases">
              LastFmImport
            </Link>
          </li>
        </ul>
      </Typography>
    </Layout>
  )
}

export default ScriptIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
